import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'radioSolid',
    name: 'Radio Solid',
    category: 'misc',
    path: (
      <rect
        fill="currentColor"
        height="15"
        rx="7.5"
        stroke="currentColor"
        width="15"
        x=".5"
        y=".5"
      />
    ),
    viewBox: '0 0 16 16',
  }
}

export const IconRadioSolid = makeIcon(def())
export default IconRadioSolid
